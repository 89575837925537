import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import GtrReportFieldSelect from '@/modules/common/components/ui-core/gtr-report-field-select/gtr-report-field-select.vue'
import { ReportableEventField, ReportableEventFields } from '@/modules/common/models/reports.model'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'
import Container from 'typedi'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import { UserInstructionSet } from '@/interfaces/common.interface'
import { userInstructionSet_Reports_Edit } from '@/bootstrap/global/user-instructions'

@Component({
  name: 'GtrEventEditReportView',
  computed: {
    ...mapState('report', ['report']),
    ...mapState('option', ['option_groups']),
    ...mapState('formbuilder', ['eventFields', 'reportableEventFields']),
    ...mapState('event', ['event', 'events']),
    ...mapState('module', ['activatedEventModules'])
  },
  components: {
    'gtr-report-field-select': GtrReportFieldSelect
  }
})
export default class GtrEventEditReportView extends GtrSuper {
  report!: Record<string, any>;
  events!: Record<string, any>;
  event!: Record<string, any>;
  activatedEventModules!: Record<string, any>;
  userInstructionSets: UserInstructionSet[] = userInstructionSet_Reports_Edit;

  data () {
    return {
      current_step: 1,
      loading: true,
      submitting: false,
      reportEdit: {
        name: null,
        report_category: null,
        report_type: null,
        export_fields: [] as ReportableEventField[],
        gridChartFieldList: [],
        reportFieldsOptions: [],
        criteria: null,
        zone_data: [],
        sort_by: ''
      },
      field: {
        field: null,
        type: null,
        graphName: null,
        display_type: null,
        graphFieldList: null,
        gridChartFieldList: null,
        fieldChartName: null,
        fieldChartFieldList: null,
        bigNumberName: null,
        bigNumberFieldList: null,
        bigNumberCalculation: null,
        textDescription: null,
        showMenu: null
      },
      choices: {
        report_categories: [
          {
            text: 'General',
            value: 'General'
          },
          {
            text: 'Accounting',
            value: 'Accounting'
          },
          {
            text: 'Registration',
            value: 'Registration'
          },
          {
            text: 'Lead Retrieval',
            value: 'Lead Retrieval'
          },
          {
            text: 'Badge Printing',
            value: 'Badge Printing'
          }
        ],
        itemsFields: [],
        itemsGroups: [],
        report_types: [
          {
            text: 'Interactive Report',
            value: 'ZONE'
          },
          {
            text: 'Spreadsheet Report',
            value: 'SPREADSHEET'
          },
          {
            text: 'Transaction Report',
            value: 'TRANSACTION'
          }
        ],
        graph_types: [
          {
            label: 'Pie',
            value: 'pie'
          },
          {
            label: 'Bar',
            value: 'bar'
          },
          {
            label: 'Polar',
            value: 'polarArea'
          }
        ],
        widgetTyped: [
          {
            text: 'Grid',
            value: 'grid'
          },
          {
            text: 'Graph',
            value: 'pie'
          },
          {
            text: 'Chart',
            value: 'fieldChart'
          },
          {
            text: 'Number',
            value: 'bigNumber'
          },
          {
            text: 'Text',
            value: 'text'
          }
        ],
        bigNumberTypes: [
          {
            label: 'Count',
            value: 'count'
          },
          {
            label: 'Sum',
            value: 'sum'
          }
        ],
        allFields: [] as ReportableEventField[],
        allOptions: []
      },
      activeReportableEventFields: {} as ReportableEventFields,
      showImportDialog: false,
      eventToImportFrom: null,
      importErrors: null,
      eventReports: [],
      reportToImport: null,
      fetchingEvents: false,
      fetchingReports: false,
      duplicateReportName: ''
    }
  }

  async mounted () {
    if (this.events.length === 0) {
      this.$data.fetchingEvents = true
      await this.$store.dispatch('event/loadEvents')
      this.$data.fetchingEvents = false
    }
    this.$store.commit('report/SET_REPORT', {})
    await this.fetchData()
  }

  get availableEvents () {
    const company = this.events.find((company) => company.uuid === this.$route.params.uuid)
    if (!company) return []
    return company.events.filter(event => event.uuid !== this.event.uuid)
  }

  set errors (errors: any) {
    this.$data.importErrors = errors
  }

  get errors () {
    if (!this.$data.importErrors) return ''
    return `${this.$data.importErrors.message}: ${this.$data.importErrors.fields.map(field => `${field.label} (${field.type})`).join(', ')}`
  }

  get reportTypes () {
    if (this.$data.reportEdit.report_type === 'TRANSACTION') {
      return this.$data.choices.report_types
    } else {
      return this.$data.choices.report_types.filter((type: any) => type.value !== 'TRANSACTION')
    }
  }

  get activeReports () {
    const reports: any[] = []

    this.$data.eventReports.forEach(report => {
      if (report.module) {
        if (
          this.activatedEventModules[report.module].enabled &&
          report.name !== 'Not Attended Report'
        ) {
          switch (report.module) {
            case 'SESSION_TRACKING':
              break
            case 'BADGES':
              if (Vue.prototype.$hasPermission('badges.badge_printing_reports.view')) {
                reports.push(report)
              }
              break
            default:
              reports.push(report)
          }
        }

        // if the end date has passed and it's not already in the list.
        if (
          report.name === 'Not Attended Report' &&
          (new Date() > new Date(this.event.event_end_date))
        ) {
          reports.push(report)
        }
      } else {
        reports.push(report)
      }
    })

    return reports
  }

  convertOldShowIfToNewFormat (payload: any) {
    const mergedShowIfObject = Object.assign({}, {
      global_type: payload.show_all ? '*' : '',
      visible: true,
      type: payload.show_all ? '*' : '',
      field: '',
      operator: '',
      value: '',
      group_operator: '',
      group_items: []
    }, payload.criteria)
    if (!payload.criteria) {
      return mergedShowIfObject
    }
    if (payload?.criteria?.show_all) {
      mergedShowIfObject.global_type = '*'
      mergedShowIfObject.type = '*'
    } else if (payload?.criteria?.type === 'grouping') {
      mergedShowIfObject.global_type = 'multiple'
    } else if (['field_criteria', 'option_criteria'].includes(payload?.criteria.type)) {
      mergedShowIfObject.global_type = 'single'
    }
    delete mergedShowIfObject.field_or_option
    delete mergedShowIfObject.show_if
    delete mergedShowIfObject.show_if_type
    return mergedShowIfObject
  }

  @Watch('reportEdit')
  onReportEditChange (reportEdit: any) {
    // if this report is the transaction report and if not there should be no custom key in the activeReportableEventFields.
    if (reportEdit.report_type === 'TRANSACTION' && (reportEdit.name === 'Transaction Report' || reportEdit.name === 'Charges Transaction Report' || reportEdit.name === 'Void/Refund Transaction Report')) {
      this.$data.activeReportableEventFields.custom = reportEdit.export_fields
        .map(
          (field: any) => ({
            ...field,
            label: field.field.split('_').map((name: string) => name.charAt(0).toUpperCase() + name.slice(1)).join(' ')
          })
        )
    } else {
      delete this.$data.activeReportableEventFields.custom
    }
  }

  @Watch('option_groups')
  onOptionsGroupsChange (optionGroups: any[]) {
    this.$data.optionGroups = optionGroups
    const sortedOptionGroups: any[] = []
    optionGroups.forEach(option => {
      sortedOptionGroups.push({
        value: option.uuid,
        text: option.name
      })
    })
    sortedOptionGroups.sort((a, b) => a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1)
    this.$data.choices.itemsGroups = sortedOptionGroups
  }

  @Watch('reportableEventFields')
  onReportableEventFieldsChange (payload: any) {
    this.$data.choices.itemsFields = []

    // Fill reportFieldsParticipants
    if (payload.participants && (this.$data.activeReportableEventFields.participants === undefined || this.$data.activeReportableEventFields.participants.length === 0)) {
      this.$data.activeReportableEventFields.participants = []
      for (const field in payload.participants) {
        const fieldData = payload.participants[field]
        const itemData = { type: fieldData.type, field: fieldData.field, label: fieldData.label, on_form: fieldData.on_form }
        if (!fieldData.option_group_uuid) {
          this.$data.activeReportableEventFields.participants.push(itemData)
          this.$data.choices.itemsFields.push({
            text: fieldData.label || fieldData.field,
            value: fieldData.field
          })
        }
      }
    }

    // Fill reportFieldsOptions
    if (payload.option_groups && (this.$data.activeReportableEventFields.option_groups === undefined || this.$data.activeReportableEventFields.option_groups.length === 0)) {
      this.$data.activeReportableEventFields.option_groups = []
      for (const field in payload.option_groups) {
        const fieldData = payload.option_groups[field]
        const itemData = { type: fieldData.type, field: fieldData.field, label: fieldData.label, on_form: fieldData.on_form }
        this.$data.activeReportableEventFields.option_groups.push(itemData)
      }
    }

    if (payload.transactions && (this.$data.activeReportableEventFields.transactions === undefined || this.$data.activeReportableEventFields.transactions.length === 0)) {
      this.$data.activeReportableEventFields.transactions = []
      for (const field in payload.transactions) {
        if (['uuid', 'amount', 'payment_type', 'payorder_number', 'check_number'].includes(field)) {
          const fieldData = payload.transactions[field]
          const itemData = {
            type: fieldData.type,
            field: fieldData.field,
            label: fieldData.label,
            on_form: fieldData.on_form
          }
          this.$data.activeReportableEventFields.transactions.push(itemData)
        }
      }
    }

    if (payload.events && (this.$data.activeReportableEventFields.events === undefined || this.$data.activeReportableEventFields.events.length === 0)) {
      this.$data.activeReportableEventFields.events = []
      for (const field in payload.events) {
        const fieldData = payload.events[field]
        const itemData = {
          type: fieldData.type,
          field: fieldData.field,
          label: fieldData.label,
          on_form: fieldData.on_form
        }
        this.$data.activeReportableEventFields.events.push(itemData)
      }
    }

    // Fill reportFieldsLeads
    if (this.$store.state.module?.activatedEventModules?.LEADS.enabled && payload.leads && (this.$data.activeReportableEventFields.leads === undefined || this.$data.activeReportableEventFields.leads.length === 0)) {
      this.$data.activeReportableEventFields.leads = []
      for (const field in payload.leads) {
        const fieldData = payload.leads[field]
        const itemData = { type: fieldData.type, field: fieldData.field, label: fieldData.label, on_form: fieldData.on_form }
        this.$data.activeReportableEventFields.leads.push(itemData)
        this.$data.choices.itemsFields.push({
          text: fieldData.label || fieldData.field,
          value: fieldData.field
        })
      }
    }

    // Fill reportFieldsParticipantGroup
    if (payload.participant_group && (this.$data.activeReportableEventFields.participant_group === undefined || this.$data.activeReportableEventFields.participant_group.length === 0)) {
      this.$data.activeReportableEventFields.participant_group = []
      for (const field in payload.participant_group) {
        const fieldData = payload.participant_group[field]
        const itemData = { type: fieldData.type, field: fieldData.field, label: fieldData.label, on_form: fieldData.on_form }
        this.$data.activeReportableEventFields.participant_group.push(itemData)
      }
    }

    // Fill promoCodes
    if (payload.promo_codes && (this.$data.activeReportableEventFields.promo_codes === undefined || this.$data.activeReportableEventFields.promo_codes.length === 0)) {
      this.$data.activeReportableEventFields.promo_codes = []
      const { code } = payload.promo_codes
      const itemData = { type: code.type, field: code.field, label: 'Promo Code', on_form: code.on_form }
      this.$data.activeReportableEventFields.promo_codes.push(itemData)
      this.$data.choices.itemsFields.push({
        text: 'Promo Code',
        value: code.field
      })
    }

    // Fill allFields
    if (payload.participants && payload.option_groups && this.$data.choices.allFields.length === 0) {
      for (const field in payload.participants) {
        const fieldData = payload.participants[field]
        const itemData = { type: fieldData.type, field: fieldData.field, label: fieldData.label, on_form: fieldData.on_form }
        this.$data.choices.allFields.push(itemData)
      }
      for (const field in payload.option_groups) {
        const fieldData = payload.option_groups[field]
        const itemData = { type: fieldData.type, field: fieldData.field, label: fieldData.label, on_form: fieldData.on_form }
        this.$data.choices.allFields.push(itemData)
      }
      if (this.$store.state.module?.activatedEventModules?.LEADS.enabled) {
        for (const field in payload.leads) {
          const fieldData = payload.leads[field]
          const itemData = { type: fieldData.type, field: fieldData.field, label: fieldData.label, on_form: fieldData.on_form }
          this.$data.choices.allFields.push(itemData)
        }
      }
    }

    // Fill allOptions
    if (payload.option_groups && this.$data.choices.allOptions.length === 0) {
      for (const field in payload.option_groups) {
        const fieldData = payload.option_groups[field]
        for (const optionIndex in fieldData.options) {
          const currentOption = fieldData.options[optionIndex]
          const label = fieldData.label + ': ' + currentOption.name
          const itemData = {
            type: fieldData.type,
            field: fieldData.field,
            value: currentOption.uuid,
            label: label,
            on_form: fieldData.on_form
          }
          this.$data.choices.allOptions.push(itemData)
        }
      }
    }

    this.$data.choices.itemsFields.sort((a, b) => a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1)
  }

  handleOpenImportReportDialog () {
    this.$data.showImportDialog = true
  }

  async fetchReportListForTargetEvent (target_uuid: string) {
    if (!target_uuid) return
    try {
      this.$data.fetchingReports = true
      const response = await this.$store.dispatch(
        'report/fetchReportListForTargetEvent',
        {
          target_uuid
        })
      this.$data.eventReports = response.data
      this.$data.fetchingReports = false
    } catch (error) {
      const errorService: ErrorHandlerService = Container.get(ErrorHandlerService)
      await errorService.error(error)
    }
  }

  setNewReportName (target_uuid: string) {
    if (!target_uuid) return
    if (this.$data.eventReports.length < 1) return
    const selectedReportName = this.$data.eventReports.find((report: any) => report.uuid === target_uuid).name
    this.$data.duplicateReportName = `${selectedReportName} - Copy`
  }

  async handleImportReport () {
    try {
      await this.$store.dispatch(
        'common/showLoader',
        { value: true }
      )
      if (!this.$data.eventToImportFrom) {
        throw new Error('Please select an event to import from.')
      }

      if (!this.$data.reportToImport) {
        throw new Error('Please select a report to import.')
      }
      const notificationService: Notification = Container.get(Notification)
      const source_event_uuid = this.$route.params.event_uuid
      await this.$store.dispatch(
        'report/importReport',
        {
          source_event_uuid,
          target_event_uuid: this.$data.eventToImportFrom,
          report_uuid: this.$data.reportToImport,
          report_name: this.$data.duplicateReportName
        }
      )
      notificationService.success('Report successfully copied.')
      this.handleCloseImportReportDialog()
      this.handleExit()
    } catch (error) {
      if (error.data) {
        this.errors = error.data
      }
      const errorService: ErrorHandlerService = Container.get(ErrorHandlerService)
      await errorService.error(error)
    } finally {
      await this.$store.dispatch('common/hideLoader')
    }
  }

  areCriteriaReadonly (report: any): boolean {
    switch (report.name) {
      case 'Group Registrations':
      case 'Group Parents':
      case 'Transaction Report':
      case 'Charges Transaction Report':
      case 'Void/Refund Transaction Report':
      case 'A2Z Exhibitor Participants':
      case 'MC Pro SSO Participants':
      case 'MC Trade SSO Participants':
        return true
      default:
        return false
    }
  }

  get availableSortFields () {
    const initialFields = [{ text: 'First Name', value: 'first_name' }, { text: 'Last Name', value: 'last_name' }, { text: 'Email', value: 'email' }, { text: 'Company', value: 'company' }, { text: 'Badge Print Date', value: 'badge_print_date' }, { text: 'Date Registered', value: 'date_registered' }]
    const allFields: any[] = []
    for (const exportFieldIndex in this.$data.reportEdit.export_fields) {
      const exportField: any = this.$data.reportEdit.export_fields[exportFieldIndex]
      const fieldToAdd = initialFields.find(i => i.value === exportField.field)
      if (fieldToAdd) {
        allFields.push(fieldToAdd)
      }
    }
    allFields.push({ text: 'Default', value: '' })
    return allFields
  }

  get editableTitle (): boolean {
    return this.areCriteriaReadonly(this.$data.reportEdit)
  }

  /**
   * This should only happen when
   * A. the user closes the dialog
   * B. the import was successful
   *
   * Close the import report dialog and reset all related state variables
   */
  handleCloseImportReportDialog () {
    this.$data.showImportDialog = false
    this.$data.duplicateReportName = ''
    this.$data.eventToImportFrom = null
    this.$data.importErrors = null
    this.$data.fetchingEvents = false
    this.$data.fetchingReports = false
  }

  handleAddReportWidget () {
    const field = Vue.prototype.$uuid.v4()
    this.$data.reportEdit.zone_data.push({
      field,
      type: '',
      label: {},
      fieldChartFieldList: {},
      gridChartFieldList: [],
      graphFieldList: {}
    })
  }

  makeChangesBasedOnFieldType (val, field) {
    /**
     * html fields need a localized object
     * other field types do not, so delete it if it is not html
     */
    if (val === 'html') {
      field.html = {}
      // field.html[this.languageToUse] = ''
    }
  }

  deleteField (payload: string) {
    this.$data.reportEdit.zone_data = this.$data.reportEdit.zone_data.filter((field: any) => field.field !== payload)
  }

  updateFieldSelection (targetFieldList: ReportableEventField[], fields: ReportableEventField[]) {
    targetFieldList.length = 0
    targetFieldList.push(...fields)
  }

  handleExit () {
    this.$store.commit('report/SET_REPORT', {})
    this.$router.push({
      name: 'level-two.event.reports',
      params: {
        event_uuid: this.$route.params.event_uuid,
        uuid: this.$route.params.uuid
      }
    })
  }

  async saveReport () {
    try {
      this.$data.submitting = true
      const payload: any = {
        event_uuid: this.$route.params.event_uuid,
        data: this.getData()
      }
      if (this.$data.reportEdit.uuid) {
        payload.report_uuid = this.$data.reportEdit.uuid
        await this.$store.dispatch('report/updateReport', payload)
        Container.get(Notification).success('Report successfully updated.')
      } else {
        await this.$store.dispatch('report/createReport', payload)
        Container.get(Notification).success('Report successfully created.')
      }
      this.handleExit()
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.submitting = false
    }
  }

  private getData () {
    const revertCustomColumns = (field) => {
      if (field.table === 'custom') {
        delete field.label
      }
      return field
    }
    return {
      name: this.$data.reportEdit.name,
      criteria: this.$data.reportEdit.criteria,
      zone_data: this.$data.reportEdit.zone_data,
      report_category: this.$data.reportEdit.report_category,
      report_type: this.$data.reportEdit.report_type,
      display_type: this.$data.field.display_type,
      export_fields: this.$data.reportEdit.export_fields.map(revertCustomColumns),
      sort_by: this.$data.reportEdit.sort_by
    }
  }

  private async fetchData () {
    try {
      this.$data.loading = true
      const { event_uuid, report_uuid = '' } = this.$route.params
      await Promise.all([
        this.$store.dispatch('option/getOptionsGroup', { event_uuid }),
        this.$store.dispatch('formbuilder/getEventFields', { event_uuid }),
        this.$store.dispatch('formbuilder/getReportableEventFields', { event_uuid })
      ])
      if (report_uuid) {
        const payload: any = {
          event_uuid: this.$route.params.event_uuid,
          report_uuid: this.$route.params.report_uuid
        }
        const response = await this.$store.dispatch('report/getReport', payload)
        if (response) {
          if (response.error) {
            this.$data.reportEdit = response.report
          } else {
            this.$data.reportEdit = response
          }
          if (!this.$data.reportEdit.sort_by) {
            this.$data.reportEdit.sort_by = ''
          }
          this.$data.reportEdit.criteria = this.convertOldShowIfToNewFormat(response)
        }
      }
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }
}
